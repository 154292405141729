import Fade from "react-reveal/Fade";
import ethBg from "image/ether-land.jpg";

const FirstSection = () => {
  return (
    <section className="pt-16 section-home-header text-white flex flex-col items-center bg-black">
      <div id="home" className="container mx-auto">
        <div className="flex flex-col justify-center items-center">
          <Fade>
            <p className="moai-title moai-font text-center">MOAI FAMILY</p>
          </Fade>
        </div>
      </div>
      <div className="land-image bg-fixed">
        <img src={ethBg} alt="land page background" />
      </div>
    </section>
  );
};
export default FirstSection;
