import { Fade } from "react-reveal";
import { useLocation } from "react-router-dom";

const blockList = {
  "/claim": true,
  "/reveal": true,
};

function Footer() {
  const { pathname } = useLocation();
  if (blockList[pathname]) return <></>;

  return (
    <footer className="footer bg-black text-white flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center py-16">
        <Fade down>
          <p className="text-xl mt-4">FOLLOW US</p>

          <div className="flex justify-center ">
            <a
              className="p-3 text-2xl"
              href="https://discord.gg/moaifamily"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-discord"></i>
            </a>
            <a
              className="p-3 text-2xl"
              href="https://twitter.com/MoaiFamily"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-twitter"></i>
            </a>
            <a
              className="p-3 text-2xl"
              href="https://www.instagram.com/moaifamily.io/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-instagram"></i>
            </a>
          </div>
        </Fade>
      </div>
      <p className="py-2">© 2022 Moai Family</p>
    </footer>
  );
}

export default Footer;
