import { useRef, useEffect, useState } from "react";
import { Fade } from "react-reveal";

const contents = [
  {
    title: (
      <div className="section-title">
        <p>TRUST</p>
      </div>
    ),
    content: (
      <>
        <p className="font-bold text-xl">Moai Family launch.</p>
      </>
    ),
  },
  {
    title: (
      <div className="section-title">
        <p>SHARING</p>
      </div>
    ),
    content: (
      <>
        <p className="font-bold text-xl">
          Exclusive channels for all the Moai Family members, we love to share
          all kinds of laughs and joy, benefits, and useful information.
        </p>
      </>
    ),
  },
  {
    title: (
      <div className="section-title">
        <p>GROWING</p>
      </div>
    ),
    content: (
      <>
        <p className="font-bold text-xl">
          We enjoy not only playing but also learning and growing together with
          family members.
        </p>
      </>
    ),
  },
  {
    title: (
      <div className="section-title">
        <p>LOVE</p>
      </div>
    ),
    content: (
      <>
        <p className="font-bold text-xl">
          Love is one of the most important things in the world. We will share
          love not only with all family members but also with those who need our
          help.
        </p>
      </>
    ),
  },
  {
    title: (
      <div className="section-title">
        <p>BUILDING</p>
      </div>
    ),
    content: (
      <div className="flex flex-col justify-center">
        <div>
          <p className="font-bold text-xl">
            Build our own "Moaiverse" for Moai Family members. Your Moai Family
            NFT will be your free pass to enter our land and to enjoy free
            concerts and play-to-earn games.
          </p>
        </div>
      </div>
    ),
  },
  {
    title: (
      <div className="section-title">
        <p>ACCOMPANY</p>
      </div>
    ),
    content: (
      <>
        <p className="font-bold text-xl">
          Hold countless online meetings and multiple offline events with our
          family members to build deeper connection with everyone.
        </p>
      </>
    ),
  },
  {
    title: (
      <div className="section-title">
        <p>FAMILY</p>
      </div>
    ),
    content: (
      <>
        <p className="font-bold text-xl">
          Keep building a lovely, healthy, organic, and strong family together.
        </p>
      </>
    ),
  },
];
const Timeline = () => {
  const timelineBar = useRef();
  const [currentCenter, setCurrentCenter] = useState(
    window.pageYOffset + window.innerHeight / 2
  );
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (timelineBar.current && timelineBar.current.offsetTop) {
      window.addEventListener("scroll", () => {
        const { offsetTop, offsetHeight } = timelineBar.current;
        const center = window.pageYOffset + window.innerHeight / 2;
        setCurrentCenter(center);
        const currentPercent = (center - offsetTop) / offsetHeight;

        setPercentage(currentPercent > 0 ? currentPercent : 0);
      });
    }
  }, [timelineBar]);

  return (
    <div className="wrapper text-white relative" ref={timelineBar}>
      <div
        className="w-[3px] top-0 bottom-0  absolute left-[32px] md:left-1/2 transform -translate-x-1/2"
        style={{
          backgroundImage: `linear-gradient(to bottom,rgb(164,118,223) ${
            percentage * 100
          }%, gray ${percentage * 100}%)`,
        }}
      />
      <div className="mt-4">
        {contents.map(({ title, content }, i) => (
          <TimeLineItem
            key={i}
            title={title}
            content={content}
            currentCenter={currentCenter}
          />
        ))}
      </div>
    </div>
  );
};
const threshold = 20;
const range = 100;
const TimeLineItem = ({ title, content, currentCenter }) => {
  const item = useRef();

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const { top } = item.current.getBoundingClientRect();
    const value = (window.innerHeight / 2 - top + threshold) / range;
    setProgress(value >= 0 ? value : 0);
  }, [currentCenter]);
  const opacityStyle = { opacity: 0.25 + progress };

  return (
    <div className={"timeline-item transition w-full"} style={opacityStyle}>
      <div className="item-title top-[50vh] h-fit">{title}</div>
      <div className="sticky top-[50vh] h-fit dot">
        <div className="rounded-full w-[15px] h-[15px] bg-white mx-auto" />
      </div>
      <div className="item-content flex flex-col justify-center " ref={item}>
        {content}
      </div>
    </div>
  );
};
const FifthSection = () => (
  <section id="roadmap" className="about bg-black text-white">
    <div className="container mx-auto">
      <div className="container-large">
        <div className="text-white py-20 flex flex-col justify-center items-center section-title">
          <Fade down>
            <p className="section-title">[ ROADMAP ]</p>
          </Fade>
        </div>
        <div className="mt-14 timeline-component mx-auto">
          <Timeline />
        </div>
      </div>
    </div>
  </section>
);
export default FifthSection;
