import { Proof } from "./merkle";
import Web3 from "web3";
import ABI from "./abi.json";
import bigNumber from "bignumber.js";
import whiteList from "./whitelist.json";

import { CONTRACT_ADDRESS, CONTRACT_ADDRESS_TOKEN } from "../constant/config";
import { GetRoot } from "./merkle";

GetRoot(whiteList);

const web3 = new Web3(window["ethereum"]);
const contractAddress = CONTRACT_ADDRESS;

export async function addTokenToMetaMask() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  const tokenAddress = CONTRACT_ADDRESS_TOKEN;
  const tokenSymbol = "MFT";
  const tokenDecimals = 18;
  // const tokenImage =
  //   "https://storage.googleapis.com/moai-storage/small-logo.png";

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    await window["ethereum"].request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          // image: tokenImage, // A string url of the token logo
        },
      },
    });
  } catch (error) {
    throw new Error("Fail to add token");
  }
}

export async function sign(address) {
  const signature = await web3.eth.personal.sign(
    "You are going to reveal your Moai Family NFT！\nRemember to turn on the sound\nWish you the best of luck：）",
    address
  );

  return signature;
}

// Get ownedTokenIds
export async function getOwnedTokenIds(address) {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.ownedTokenIds(address).call();
}

// whitelist sale
export async function GetWhiteListSaleMintPrice() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods
    .whiteListSaleMintPrice()
    .call()
    .then((price) => price);
}

export async function GetWhiteListSaleStartTime() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.whiteListSaleStartTime().call();
}

export async function GetWhiteListSaleEndTime() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.whiteListSaleEndTime().call();
}

//public sale
export async function GetPublicSaleMintPrice() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.publicSaleMintPrice().call();
}

export async function GetPublicSaleStartTime() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.publicSaleStartTime().call();
}

export async function GetPublicSaleEndTime() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.publicSaleEndTime().call();
}

export async function GetRemainingQuantity() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods
    .remainingQuantity()
    .call()
    .then((amount) => amount);
}

export function GetMerkleQuantity(address) {
  if (!address) return 0;
  let newList = {};
  for (const [key, value] of Object.entries(whiteList)) {
    newList[key.toLowerCase()] = value;
  }
  return newList[address.toLowerCase()] ?? 0;
}

export async function GetPublicSaleMaxQuantityPerTx() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.publicSaleMaxQuantityPerTx().call();
}

export async function getTotalSupply() {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.totalSupply().call();
}

export async function JoinFamily(
  address,
  amount,
  price,
  proof,
  merkleQuantity
) {
  if (!contractAddress) {
    throw new Error(
      "Please come back when public mint starts. Follow us on Twitter."
    );
  } else if (!address) {
    throw new Error("Please connect wallet first!");
  } else {
    let contract = new web3.eth.Contract(ABI, contractAddress);
    var itemPrice = new bigNumber(price);
    let sumAmount = itemPrice.multipliedBy(amount);

    const gasAmount = await contract.methods
      .joinFamily(proof, merkleQuantity, amount)
      .estimateGas({ from: address, value: sumAmount });

    const gasLimit = new bigNumber(gasAmount)
      .multipliedBy(110)
      .dividedBy(100)
      .toFixed(0);

    const res = await contract.methods
      .joinFamily(proof, merkleQuantity, amount)
      .send({ value: sumAmount, from: address, gas: gasLimit });

    return res.transactionHash;
  }
}

export function GetProof(address) {
  const merkleQuantity = GetMerkleQuantity(address);
  return Proof(address, merkleQuantity, whiteList);
}
