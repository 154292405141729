import FirstSection from "./sections/FirstSection";
import Art from "./sections/Art";
import About from "./sections/About";
import Roadmap from "./sections/Roadmap";
import Team from "./sections/Team";
import "./index.scss";

function Homepage() {
  return (
    <div className="home-page flex flex-col content-center bg-black">
      <FirstSection />
      <About />
      <Art />
      <Roadmap />
      <Team />
    </div>
  );
}

export default Homepage;
