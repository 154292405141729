import { useEffect, useRef, useReducer, useCallback } from "react";
import LoadingButton from "components/LoadingButton";
import Fade from "react-reveal/Fade";
import Countdown from "react-countdown";
import MetaMaskOnboarding from "@metamask/onboarding";
import {
  GetPublicSaleEndTime,
  GetPublicSaleStartTime,
  GetPublicSaleMintPrice,
  GetPublicSaleMaxQuantityPerTx,
  GetWhiteListSaleStartTime,
  GetWhiteListSaleEndTime,
  GetWhiteListSaleMintPrice,
  GetRemainingQuantity,
  GetMerkleQuantity,
  GetProof,
  JoinFamily,
} from "../../services/wallet";
import { ENV, OPENSEA } from "../../constant/config";
import bigNumber from "bignumber.js";
import "./index.scss";

const ETHER_GWEI = 1000000000000000000;

const stageText = {
  comingSoon: "Coming soon",
  privateSale: "Whitelist pre-sale",
  publicSale: "Public sale",
  buyOpenSea: "Buy on openSea",
};

const initialState = {
  isConnected: false,
  address: null,
  remainAmount: 1800,
  price: 0.198,
  stage: stageText.comingSoon,
  maximumMint: 0,
  successMessage: "",
  errorMessage: "",
  totalAmount: 1800,
  mintNumber: 0,
  countDown: 0,
  isLoading: false,
  buyPercentage: 0,
};

function reducer(state, action) {
  switch (action.type) {
    case "setConnectedState":
      return { ...state, isConnected: action.payload };
    case "setAccount":
      return { ...state, address: action.payload };
    case "setTotalAmount":
      return { ...state, totalAmount: action.payload };
    case "setRemainAmount":
      return { ...state, remainAmount: action.payload };
    case "setPrice":
      return { ...state, price: action.payload };
    case "setStage":
      return { ...state, stage: action.payload };
    case "setMaximumMint":
      return { ...state, maximumMint: action.payload };
    case "setMintNumber":
      return { ...state, mintNumber: action.payload };
    case "setErrorMessage":
      return { ...state, errorMessage: action.payload };
    case "setSuccessMessage":
      return { ...state, successMessage: action.payload };
    case "setCountDown":
      return { ...state, countDown: action.payload };
    case "setIsLoading":
      return { ...state, isLoading: action.payload };
    case "setWhiteListSaleStartTime":
      return { ...state, whiteListSaleStartTime: action.payload };
    case "setWhiteListSaleEndTime":
      return { ...state, whiteListSaleEndTime: action.payload };
    case "setBuyPercentage":
      return { ...state, buyPercentage: action.payload };
    default:
      throw new Error();
  }
}

function OnboardingButton() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    isConnected,
    address,
    remainAmount,
    totalAmount,
    price,
    stage,
    maximumMint,
    errorMessage,
    mintNumber,
    countDown,
    isLoading,
    whiteListSaleStartTime,
    whiteListSaleEndTime,
    successMessage,
    buyPercentage,
  } = state;

  const onboarding = useRef();

  function initMintAndMaxMint(_address) {
    const merkleQuantity = GetMerkleQuantity(_address);

    dispatch({
      type: "setMaximumMint",
      payload: +merkleQuantity,
    });
    dispatch({
      type: "setMintNumber",
      payload: +merkleQuantity,
    });
  }

  const initialTimer = useCallback(
    async ({
      publicSaleEndTime,
      publicSaleMintPrice,
      publicSaleStartTime,
      whiteListSaleEndTime,
      whiteListSaleMintPrice,
      whiteListSaleStartTime,
    }) => {
      const now = Date.now();
      if (now >= publicSaleEndTime) {
        dispatch({ type: "setStage", payload: stageText.buyOpenSea });
      } else if (now >= publicSaleStartTime) {
        const publicAmount = await GetPublicSaleMaxQuantityPerTx();
        dispatch({
          type: "setMaximumMint",
          payload: +publicAmount,
        });
        dispatch({
          type: "setMintNumber",
          payload: +publicAmount,
        });
        dispatch({ type: "setStage", payload: stageText.publicSale });
        dispatch({ type: "setCountDown", payload: publicSaleEndTime });
        dispatch({
          type: "setPrice",
          payload: bigNumber(publicSaleMintPrice)
            .dividedBy(ETHER_GWEI)
            .toNumber(),
        });
        if (publicSaleEndTime)
          setTimeout(() => {
            initialTimer({
              publicSaleEndTime,
              publicSaleMintPrice,
              publicSaleStartTime,
              whiteListSaleEndTime,
              whiteListSaleMintPrice,
              whiteListSaleStartTime,
            });
          }, publicSaleEndTime - now + 1000);
      } else if (now >= whiteListSaleStartTime) {
        // private sale start
        dispatch({ type: "setStage", payload: stageText.privateSale });
        dispatch({ type: "setCountDown", payload: whiteListSaleEndTime });
        dispatch({
          type: "setPrice",
          payload: bigNumber(whiteListSaleMintPrice)
            .dividedBy(ETHER_GWEI)
            .toNumber(),
        });
        // initMintAndMaxMint(address);
        if (whiteListSaleEndTime)
          setTimeout(() => {
            initialTimer({
              publicSaleEndTime,
              publicSaleMintPrice,
              publicSaleStartTime,
              whiteListSaleEndTime,
              whiteListSaleMintPrice,
              whiteListSaleStartTime,
            });
          }, whiteListSaleEndTime - now + 1000);
      } else {
        dispatch({ type: "setStage", payload: stageText.comingSoon });
        dispatch({ type: "setCountDown", payload: whiteListSaleStartTime });
        dispatch({
          type: "setPrice",
          payload: bigNumber(whiteListSaleMintPrice)
            .dividedBy(ETHER_GWEI)
            .toNumber(),
        });
        if (whiteListSaleStartTime)
          setTimeout(() => {
            initialTimer({
              publicSaleEndTime,
              publicSaleMintPrice,
              publicSaleStartTime,
              whiteListSaleEndTime,
              whiteListSaleMintPrice,
              whiteListSaleStartTime,
            });
          }, whiteListSaleStartTime - now + 1000);
      }
    },
    []
  );

  const initialContract = useCallback(async () => {
    try {
      const publicSaleStartTime = (await GetPublicSaleStartTime()) * 1000;
      const publicSaleEndTime = (await GetPublicSaleEndTime()) * 1000;
      const whiteListSaleStartTime = (await GetWhiteListSaleStartTime()) * 1000;
      const whiteListSaleEndTime = (await GetWhiteListSaleEndTime()) * 1000;

      // const now = Date.now();
      // const publicSaleEndTime = now + 18000;
      // const publicSaleStartTime = now + 9000;
      // const whiteListSaleEndTime = now + 9000;
      // const whiteListSaleStartTime = now + 3000;
      // console.log(whiteListSaleStartTime);
      // console.log(whiteListSaleEndTime);
      // console.log(publicSaleStartTime);
      // console.log(publicSaleEndTime);

      const whiteListSaleMintPrice = await GetWhiteListSaleMintPrice();
      const publicSaleMintPrice = await GetPublicSaleMintPrice();
      const remainAmount = await GetRemainingQuantity();

      dispatch({ type: "setRemainAmount", payload: +remainAmount });
      dispatch({
        type: "setWhiteListSaleStartTime",
        payload: whiteListSaleStartTime,
      });
      dispatch({
        type: "setWhiteListSaleEndTime",
        payload: whiteListSaleEndTime,
      });

      initialTimer({
        publicSaleEndTime,
        publicSaleMintPrice,
        publicSaleStartTime,
        whiteListSaleEndTime,
        whiteListSaleMintPrice,
        whiteListSaleStartTime,
      });
    } catch (error) {
      const netID = Number(window.ethereum.chainId);
      if (netID !== 1 && ENV === "prod") {
        dispatch({
          type: "setErrorMessage",
          payload: "Please chose Ethereum Mainnet",
        });
      } else if (netID !== 4 && ENV !== "prod") {
        dispatch({
          type: "setErrorMessage",
          payload: "Please chose Rinkeby Mainnet",
        });
      } else {
        dispatch({
          type: "setErrorMessage",
          payload: "Initial contract error",
        });
      }
    }
  }, [initialTimer]);

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
    initialContract();
  }, [initialContract]);

  useEffect(() => {
    const handleNewAccounts = (newAccounts) => {
      dispatch({ type: "setAccount", payload: newAccounts[0] });
    };
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.on("accountsChanged", handleNewAccounts);
      return () => {
        if (window.ethereum.off && window.ethereum) {
          window.ethereum.off("accountsChanged", handleNewAccounts);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (!MetaMaskOnboarding.isMetaMaskInstalled()) return;

    if (!address) {
      dispatch({ type: "setConnectedState", payload: false });
    } else {
      dispatch({ type: "setConnectedState", payload: true });

      const now = Date.now();
      if (now <= whiteListSaleEndTime) {
        initMintAndMaxMint(address);
      }
      onboarding.current.stopOnboarding();
    }
  }, [address, whiteListSaleStartTime, whiteListSaleEndTime]);

  useEffect(() => {
    const result = Math.floor(
      ((totalAmount - remainAmount) * 100) / totalAmount
    );

    dispatch({ type: "setBuyPercentage", payload: result });
  }, [remainAmount, totalAmount]);

  const setMintNumber = (event) => {
    event.preventDefault();
    dispatch({ type: "setMintNumber", payload: +event.target.value });
  };

  const connectMetaMask = async () => {
    dispatch({
      type: "setErrorMessage",
      payload: "",
    });
    try {
      if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
        onboarding.current.startOnboarding();
        return;
      }

      const newAccounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const netID = Number(window.ethereum.chainId);
      if (netID !== 1 && ENV === "prod") {
        dispatch({
          type: "setErrorMessage",
          payload: "Please chose Ethereum Mainnet",
        });
      } else if (netID !== 4 && ENV !== "prod") {
        dispatch({
          type: "setErrorMessage",
          payload: "Please chose Rinkeby Mainnet",
        });
      } else {
        dispatch({ type: "setAccount", payload: newAccounts[0] });
        dispatch({
          type: "setSuccessMessage",
          payload: "Connect success!",
        });
        if (!whiteListSaleStartTime) {
          initialContract();
        }
      }
    } catch (error) {
      if (error.message.includes("Already processing eth_requestAccounts")) {
        dispatch({
          type: "setErrorMessage",
          payload: "Please unlock your metamask",
        });
      } else {
        dispatch({ type: "setErrorMessage", payload: error.message });
      }
    }
  };

  const clickMint = async () => {
    dispatch({
      type: "setSuccessMessage",
      payload: "",
    });
    dispatch({
      type: "setErrorMessage",
      payload: "",
    });
    try {
      if (mintNumber < 1) {
        dispatch({
          type: "setErrorMessage",
          payload: "Mint number should bigger than 0",
        });
        return;
      }

      if (mintNumber > maximumMint) {
        dispatch({
          type: "setErrorMessage",
          payload: "Mint number should smaller than max mint",
        });
        return;
      }

      let proof = GetProof(address);
      let merkleQuantity = maximumMint;
      dispatch({
        type: "setIsLoading",
        payload: true,
      });
      await JoinFamily(
        address,
        mintNumber,
        bigNumber(price).multipliedBy(ETHER_GWEI).toNumber(),
        proof,
        merkleQuantity
      );

      dispatch({
        type: "setSuccessMessage",
        payload: "Congrats! Mint completed",
      });
    } catch (error) {
      if (error.message.includes("insufficient funds")) {
        dispatch({
          type: "setErrorMessage",
          payload: "Not enough ETH",
        });
      } else if (stage === stageText.privateSale) {
        dispatch({
          type: "setErrorMessage",
          payload: "You are not in WL or already minted",
        });
      } else {
        dispatch({
          type: "setErrorMessage",
          payload: "Invalid mint",
        });
      }
    } finally {
      dispatch({
        type: "setIsLoading",
        payload: false,
      });
    }
  };

  const onTick = () => {
    try {
      GetRemainingQuantity().then((quantity) => {
        dispatch({ type: "setRemainAmount", payload: +quantity });
      });
    } catch (error) {
      dispatch({ type: "setContractAddressExist", payload: false });
    }
  };

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds }) => {
    // Render a countdown
    return (
      <div className="flex font-semibold text-4xl pb-6 md:pb-14">
        <div className="text-center  p-4">
          <p>Day</p>
          <p>{days}</p>
        </div>
        <div className="text-center  p-4">
          <p> Hour</p>
          <p> {hours}</p>
        </div>
        <div className="text-center  p-4">
          <p> Min</p>
          <p> {minutes}</p>
        </div>
        <div className="text-center  p-4">
          <p> Sec</p>
          <p> {seconds}</p>
        </div>
      </div>
    );
  };

  const Alert = ({ color, message, close }) => {
    return (
      <div className="text-center py-4 lg:px-4 absolute alert">
        <div
          className={`p-2 ${
            color === "green" ? "bg-green-700" : "bg-red-700"
          }  items-center text-white leading-none rounded-xl flex relative`}
          role="alert"
        >
          <p className="font-semibold mr-2 text-left flex-auto w-max">
            {message}
          </p>
          <p className="right-0" onClick={close}>
            <svg
              className={`fill-current h-6 w-6 hover:text-gray-600 text-white`}
              role="button"
            >
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="mint-page pt-14">
      <div className="mx-auto grid  xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 ">
        <div className="flex flex-col items-center justify-center rounded-xl shadow card row-span-2 col-span-2 grid-cols-2 m-6 bg-gray-800 border-gray-700 border text-white">
          <Fade className="w-full" bottom>
            <div className="flex flex-col justify-center items-center relative pt-6  mb-10">
              {remainAmount !== 0 && stage !== stageText.buyOpenSea && (
                <Countdown
                  key={countDown}
                  date={countDown}
                  renderer={renderer}
                  onTick={onTick}
                />
              )}
              {remainAmount === 0 || stage === stageText.buyOpenSea ? (
                <div className="mb-6 flex flex-col justify-center items-center ">
                  <button
                    className="mt-4 mb-2 button rounded-3xl shadow-md max-w-min font-semibold whitespace-nowrap"
                    onClick={() => window.open(OPENSEA)}
                  >
                    Buy on opensea
                  </button>
                </div>
              ) : (
                <div className="w-full mb-6 flex flex-col justify-center items-center ">
                  {isConnected &&
                    (stage === stageText.privateSale ||
                      stage === stageText.publicSale) && (
                      <div className="w-full flex flex-col justify-center items-center">
                        <div className="flex justify-center items-center number-input">
                          <button
                            className="plus"
                            onClick={() => {
                              dispatch({
                                type: "setMintNumber",
                                payload: mintNumber + 1,
                              });
                            }}
                          ></button>
                          <input
                            type="number"
                            onChange={setMintNumber}
                            value={mintNumber}
                          />
                          <button
                            onClick={() => {
                              dispatch({
                                type: "setMintNumber",
                                payload: mintNumber - 1,
                              });
                            }}
                          />
                        </div>
                        <div className="flex justify-center items-center">
                          <div className="pr-6 max-w-min font-semibold text-4xl whitespace-nowrap">
                            {bigNumber(mintNumber)
                              .multipliedBy(price)
                              .toNumber()}{" "}
                            ETH
                          </div>
                          <LoadingButton
                            className="mt-4 mb-4 button rounded-xl shadow-md max-w-min font-semibold"
                            onClick={clickMint}
                            isLoading={isLoading}
                          >
                            Mint
                          </LoadingButton>
                        </div>
                      </div>
                    )}
                  {!isConnected && (
                    <LoadingButton
                      className="mt-4 mb-4 button rounded-3xl shadow-md max-w-min font-semibold"
                      onClick={connectMetaMask}
                      isLoading={isLoading}
                    >
                      Connect
                    </LoadingButton>
                  )}
                  <div className="w-full text-lg">
                    <div className="pb-2">
                      <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                        <div
                          className="w-full font-bold bg-black font-medium text-center p-1.5 leading-none rounded-full"
                          style={{
                            width: buyPercentage + "%",
                          }}
                        >
                          {buyPercentage}%
                        </div>
                      </div>
                    </div>
                    <p className="my-2 font-semibold ">Stage : {stage}</p>
                    <p className="my-2 font-semibold whitespace-nowrap">
                      Supply : {remainAmount} / {totalAmount}
                    </p>
                    <p className="my-2 font-semibold">Price : {price} ETH</p>
                    {stage !== stageText.comingSoon && (
                      <p className="my-2 font-semibold">
                        Max mint : {maximumMint}
                      </p>
                    )}
                    <p className="my-2 font-semibold address">
                      Wallet address : {address}
                    </p>
                  </div>
                </div>
              )}

              {successMessage && (
                <Alert
                  {...{
                    color: "green",
                    title: "success",
                    message: successMessage,
                    close: () => {
                      dispatch({ type: "setSuccessMessage", payload: "" });
                    },
                  }}
                />
              )}
              {errorMessage && (
                <Alert
                  {...{
                    color: "red",
                    title: "Error",
                    message: errorMessage,
                    close: () => {
                      dispatch({ type: "setErrorMessage", payload: "" });
                    },
                  }}
                />
              )}
            </div>
          </Fade>
        </div>
        <div className="box">
          <img
            src={require("../../image/art/1.png")}
            alt="Moai Family Preview"
          />
        </div>
        <div className="box">
          <img
            src={require("../../image/art/4.png")}
            alt="Moai Family Preview"
          />
        </div>
        <div className="box">
          <img
            src={require("../../image/art/2.png")}
            alt="Moai Family Preview"
          />
        </div>
        <div className="box">
          <img
            src={require("../../image/art/11.png")}
            alt="Moai Family Preview"
          />
        </div>
        <div className="box">
          <img
            src={require("../../image/art/9.png")}
            alt="Moai Family Preview"
          />
        </div>

        <div className="box">
          <img
            src={require("../../image/art/14.png")}
            alt="Moai Family Preview"
          />
        </div>
        <div className="box">
          <img
            src={require("../../image/art/15.png")}
            alt="Moai Family Preview"
          />
        </div>

        <div className="box">
          <img
            src={require("../../image/art/10.png")}
            alt="Moai Family Preview"
          />
        </div>
      </div>
    </div>
  );
}

export default OnboardingButton;
