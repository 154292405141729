import { Fade } from "react-reveal";
import sun from "image/sun.png";

const SixthSection = () => {
  const teamMembers = [
    {
      image: require("../../../image/team/loreneJen.jpeg"),
      name: "LoreneJen",
      Description: () => (
        <p className="pt-3">
          A well-known actress active in Asia who has deep affection for the
          Crypto and NFT world. She always cares her community like it's her own
          family and believes that Web3.0 is changing the world.
        </p>
      ),
      Link: () => (
        <div className="pt-2">
          <a
            className="pr-3"
            href="https://www.facebook.com/renrongrong1122"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            className="pr-3"
            href="https://instagram.com/lorenejen"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a
            className="pr-3"
            href="https://twitter.com/LoreneJen1122"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-twitter"></i>
          </a>
        </div>
      ),
    },
    {
      image: require("../../../image/team/wilsonBayc.png"),
      name: "Wilsonhuang.eth",
      Description: () => (
        <p className="pt-3">
          Founder of Steaker and XY Finance.
          <br />
          Started crypto life in 2014.
          <br /> Participates in the expansion of second layer Ethereum.
          <br /> Generation T in 2021 by Tatler Magazine.
          <br />
          BAYC #6446
        </p>
      ),
      Link: () => (
        <div className="pt-2">
          <a
            className="pr-3"
            href="https://twitter.com/w9geth"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-twitter"></i>
          </a>
        </div>
      ),
    },
    {
      image: require("../../../image/team/warrenBayc.png"),
      name: "Warrencheng.eth",
      Description: () => (
        <p className="pt-3">
          Solidity engineer
          <br />
          BAYC #5523
        </p>
      ),
      Link: () => (
        <div className="pt-2">
          <a
            className="pr-3"
            href="https://twitter.com/warrenycheng"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-twitter"></i>
          </a>
        </div>
      ),
    },
    {
      image: require("../../../image/art/5.png"),
      name: "Bruce",
      Description: () => (
        <p className="pt-3">
          Best known for his strange creation and mixed art. Although having
          years of experience in drawing, he likes to claim that it all started
          in 2020 just because the number is romantic. ''The weirdness is
          talent''
        </p>
      ),
      Link: () => (
        <div className="pt-2">
          <a
            className="pr-3"
            href="https://www.instagram.com/just.kidding.tw/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-instagram"></i>
          </a>
        </div>
      ),
    },
    {
      image: require("../../../image/art/6.png"),
      name: "Wing",
      Description: () => (
        <p className="pt-3">
          CEO of Go-Do Studio. Expert in Premiere, After Effects, and various
          video shooting, editing, post-production editing. Experienced in new
          media marketing and community management.
        </p>
      ),
      Link: () => (
        <div className="pt-2">
          <a
            className="pr-3"
            href="https://www.facebook.com/go.do.people"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            className="pr-3"
            href="https://reurl.cc/5GA5k7"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-instagram"></i>
          </a>
        </div>
      ),
    },
    {
      image: require("../../../image/art/7.png"),
      name: "Zedd",
      Description: () => (
        <p className="pt-3">
          Seasoned tech lead. Proficient in cloud services, server, frontend,
          backend, and smart contract development.
        </p>
      ),
    },
    {
      image: require("../../../image/art/8.png"),
      name: "Lumo",
      Description: () => (
        <p className="pt-3">
          Key Visual Designer of Moai Family. An artist specializing in Impasto
          and Anime style. Lumo joined the crypto market in 2019 and the NFT
          market in 2020.
        </p>
      ),
      Link: () => (
        <div className="pt-2">
          <a
            className="pr-3"
            href="https://instagram.com/lumo0224_ouo"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-instagram"></i>
          </a>
        </div>
      ),
    },
    {
      image: require("../../../image/art/9.png"),
      name: "IT",
      Description: () => (
        <p className="pt-3">All in crypto and NFT for years.</p>
      ),
    },
  ];

  const partner = [
    {
      image: require("../../../image/partner/4.jpg"),
      link: "https://enyafashionqueen.com/",
    },
    {
      image: require("../../../image/partner/5.png"),
      link: "http://www.finessewines.com.tw/ ",
    },
    {
      image: require("../../../image/partner/20.jpg"),
      link: "https://www.facebook.com/wonderlfulland",
    },
    {
      image: require("../../../image/partner/3.jpg"),
      link: "https://www.facebook.com/drunkplay4fun/",
    },
    {
      image: require("../../../image/partner/17.jpg"),
      link: "https://www.facebook.com/go.do.people/",
    },
    {
      image: require("../../../image/partner/8.jpg"),
      link: "https://www.armytrix.com/",
    },
    {
      image: require("../../../image/partner/1.jpg"),
      link: "https://www.facebook.com/mansiontaiwan.toucheng/",
    },
    {
      image: require("../../../image/partner/10.png"),
      link: "https://xy.finance/",
    },
    {
      image: require("../../../image/partner/6.jpg"),
      link: "https://www.redreamer.io/",
    },
    {
      image: require("../../../image/partner/2.jpg"),
      link: "https://www.facebook.com/atdrunk8787/",
    },
    {
      image: require("../../../image/partner/18.png"),
      link: "https://moist.taipei/",
    },
    {
      image: require("../../../image/partner/16.png"),
    },
    {
      image: require("../../../image/partner/7.jpg"),
      link: "https://24h.pchome.com.tw/store/DICY25",
    },
    {
      image: require("../../../image/partner/15.jpg"),
      link: "https://www.bornrichworldwide.com/",
    },
    {
      image: require("../../../image/partner/12.jpg"),
    },
    {
      image: require("../../../image/partner/9.png"),
      link: "https://mixclodesign.com/",
    },
    {
      image: require("../../../image/partner/11.png"),
      link: "https://steaker.com/zh-TW/",
    },
    {
      image: require("../../../image/partner/19.jpg"),
      link: "https://car-repair-142.business.site/",
    },
    {
      image: require("../../../image/partner/14.jpg"),
      link: "https://blocto.portto.io/zh/",
    },
  ];

  function Team({ image, title, name, Description, Link = () => null }) {
    return (
      <div className="team-item">
        <div>
          <img className="rounded-3xl" src={image} alt="Team" />
        </div>
        <div className="min-height-[20rem] p-4 bg-black text-white">
          <div>
            <p>{title}</p>
          </div>
          <div className="moai-font text-4xl name py-3">
            <p>{name}</p>
          </div>
          <div className="font-bold text-xl">
            <Description />
            <Link />
          </div>
        </div>
      </div>
    );
  }

  function Partner({ image, link = null }) {
    return (
      <div className="partner-item flex flex-col justify-center items-center">
        <div
          className="bg-black"
          onClick={() => {
            if (link) window.open(link);
          }}
        >
          <img className="rounded-3xl bg-transparent" src={image} alt="Team" />
        </div>
      </div>
    );
  }

  return (
    <section className="flex flex-col items-center bg-black text-white">
      <div className="container mx-auto">
        <div id="team">
          <div className="pt-20 pb-10 flex flex-col justify-center items-center section-title">
            <Fade down>
              <p className="section-title mt-4">[ TEAM ]</p>
            </Fade>
          </div>
        </div>
        <div className="team-component">
          <Fade>
            {teamMembers.map((member) => (
              <Team {...member} />
            ))}
          </Fade>
        </div>
        <div id="partner">
          <div className="pt-20 pb-10 flex flex-col justify-center items-center section-title">
            <Fade down>
              <p className="section-title mt-4">[ PARTNER ]</p>
            </Fade>
          </div>
        </div>
        <div className="partner-component">
          {partner.map((member) => (
            <Partner {...member} />
          ))}
        </div>

        <div id="info">
          <div className="py-20 flex flex-col justify-center items-center section-title">
            <Fade down>
              <p className="section-title mt-4">[ INFORMATION ]</p>
            </Fade>
          </div>
        </div>
        <div className=" flex flex-col justify-center items-center font-bold text-xl">
          <Fade left>
            <div className="pb-14 text-2xl grid gap-12">
              <div className="flex">
                <div className="flex items-center">
                  <img className="sun" alt="sun" src={sun} />
                  &nbsp; Total NFTs: 1,800
                </div>
              </div>
              <div className="flex">
                <div className="flex items-center">
                  <img className="sun" alt="sun" src={sun} />
                  &nbsp; Team reserved: 180
                </div>
              </div>
              <div className="flex">
                <div className="flex items-center">
                  <img className="sun" alt="sun" src={sun} />
                  &nbsp; Blockchain: Ethereum
                </div>
              </div>
              <div className="flex">
                <div className="flex items-center">
                  <img className="sun" alt="sun" src={sun} />
                  &nbsp; NFT Standard: ERC-721A
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};
export default SixthSection;
