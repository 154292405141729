import Web3 from "web3";
import bigNumber from "bignumber.js";
import ABI from "./abi.json";
import { CONTRACT_ADDRESS_REWARD } from "constant/config";

const web3 = new Web3(window["ethereum"]);
const contractAddress = CONTRACT_ADDRESS_REWARD;

// Get claimableForUser
async function getClaimableForUser(address) {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.claimableForUser(address).call();
}

// Get claimableForTokens
async function getClaimableForTokens(tokenIds) {
  if (!window["ethereum"]) throw new Error("Please install Metamask");
  await window["ethereum"].enable();
  let contract = new web3.eth.Contract(ABI, contractAddress);
  return contract.methods.claimableForTokens(tokenIds).call();
}

async function claimToken(tokenIds, address) {
  if (tokenIds.length === 0) {
    throw new Error("Please select token!");
  }

  let contract = new web3.eth.Contract(ABI, contractAddress);

  const gasAmount = await contract.methods
    .claim(tokenIds)
    .estimateGas({ from: address });

  const gasLimit = new bigNumber(gasAmount)
    .multipliedBy(110)
    .dividedBy(100)
    .toFixed(0);

  const res = await contract.methods
    .claim(tokenIds)
    .send({ from: address, gas: gasLimit });

  return res.transactionHash;
}

export { getClaimableForUser, getClaimableForTokens, claimToken };
