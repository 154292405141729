import React from "react";
import { Route, Routes } from "react-router-dom";
import Mint from "page/mint/index";
import Claim from "page/claim/index";
import Reveal from "page/reveal/index";
import Homepage from "page/homepage/index";

const MainRoutes = () => {
  return (
    <div className="app-router-container">
      <Routes>
        <Route exact path={"/"} element={<Homepage />} />
        <Route exact path={"/claim"} element={<Claim />} />
        <Route exact path={"/reveal"} element={<Reveal />} />
        <Route path={"/mint"} element={<Mint />} />
        <Route path="*" element={<Homepage />} />
      </Routes>
    </div>
  );
};

export { MainRoutes };
