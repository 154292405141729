import React, { useState, useEffect, useRef, useReducer } from "react";
import party from "party-js";
import LoadingButton from "components/LoadingButton";
import MetaMaskOnboarding from "@metamask/onboarding";
import detectEthereumProvider from "@metamask/detect-provider";
import { useTranslation } from "react-i18next";
import { getOwnedTokenIds, sign } from "../../services/wallet";
import {
  ENV,
  GOOGLE_BLIND_BOX,
  GOOGLE_NFT_PATH,
  IPFS_BLIND_BOX,
} from "../../constant/config";
import { getTokensData, revealToken } from "../../api";
import "./index.scss";

const initialState = {
  isConnected: false,
  address: null,
  successMessage: "",
  errorMessage: "",
  connectError: "",
  isLoading: false,
  accountReward: 0,
  accountTokensId: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "setConnectedState":
      return { ...state, isConnected: action.payload };
    case "setAccount":
      return { ...state, address: action.payload };
    case "setErrorMessage":
      return { ...state, errorMessage: action.payload };
    case "setSuccessMessage":
      return { ...state, successMessage: action.payload };
    case "setIsLoading":
      return { ...state, isLoading: action.payload };
    case "setAccountReward":
      return { ...state, accountReward: action.payload };
    case "setAccountTokensId":
      return { ...state, accountTokensId: action.payload };
    case "setShowModel":
      return { ...state, showModel: action.payload };
    case "setSelectedToken":
      return { ...state, selectedToken: action.payload };
    case "setConnectError":
      return { ...state, connectError: action.payload };
    default:
      throw new Error();
  }
}

const Token = React.memo(({ tokenData, dispatch }) => {
  const { t } = useTranslation();
  const isBlindBox = tokenData.image === IPFS_BLIND_BOX;

  const imageName = isBlindBox
    ? GOOGLE_BLIND_BOX
    : `${GOOGLE_NFT_PATH}${
        tokenData.image.includes("ipfs")
          ? tokenData.image.split("/")[3]
          : tokenData.image.split("/")[5]
      }`;

  return (
    <div
      className="flex flex-col items-center p-5"
      onClick={() => {
        dispatch({ type: "setSuccessMessage", payload: "" });
        dispatch({ type: "setErrorMessage", payload: "" });
        dispatch({
          type: "setSelectedToken",
          payload: tokenData,
        });
        dispatch({
          type: "setShowModel",
          payload: true,
        });
      }}
    >
      <p className="mt-4 font-semibold text-2xl">#{tokenData.tokenId}</p>
      <img className="pic" src={imageName} alt="Moai Family" />
      <LoadingButton>{t("select")}</LoadingButton>
    </div>
  );
});

const Alert = ({ color, message, close }) => {
  return (
    <div className="text-centers alert hover:text-black" onClick={close}>
      <button
        className={`p-1 ${
          color === "green" ? "bg-green-700" : "bg-red-700"
        }  flex flex-row justify-center items-center leading-none rounded-xlrelative`}
        role="alert"
      >
        <div className="font-semibold text-4xl mr-2 ml-2 text-left flex-auto">
          {message}
        </div>
        <div
          className="flex flex-col justify-center items-center font-semibold text-2xl"
          onClick={close}
        >
          x
        </div>
      </button>
    </div>
  );
};

function Claim() {
  const mainRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [accountTokens, setAccountTokens] = useState([]);
  const [revealing, setRevealing] = useState(false);
  const {
    selectedToken,
    showModel,
    isConnected,
    address,
    isLoading,
    errorMessage,
    successMessage,
    connectError,
  } = state;
  const { t } = useTranslation();

  const onboarding = useRef();

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
    const handleNewAccounts = (newAccounts) => {
      dispatch({ type: "setAccount", payload: newAccounts[0] });
    };
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.on("accountsChanged", handleNewAccounts);
      return () => {
        if (window.ethereum.off && window.ethereum) {
          window.ethereum.off("accountsChanged", handleNewAccounts);
        }
      };
    }
  }, []);

  const initialContract = React.useCallback(async () => {
    try {
      dispatch({
        type: "setIsLoading",
        payload: true,
      });
      // init must have address
      if (!address) return;
      const accountTokensId = await getOwnedTokenIds(address);
      if (!accountTokensId.length > 0)
        return dispatch({
          type: "setConnectError",
          payload: "No token in this address",
        });

      const tokensData = await getTokensData({
        tokensId: accountTokensId.join(),
      });

      setAccountTokens(tokensData);

      dispatch({
        type: "setAccountTokensId",
        payload: accountTokensId,
      });
    } catch (error) {
      dispatch({
        type: "setConnectError",
        payload: "Initial contract error",
      });
    } finally {
      dispatch({
        type: "setIsLoading",
        payload: false,
      });
    }
  }, [address]);

  useEffect(() => {
    if (!MetaMaskOnboarding.isMetaMaskInstalled()) return;

    if (address) {
      dispatch({ type: "setConnectedState", payload: true });
      onboarding.current.stopOnboarding();
      initialContract();
    } else {
      dispatch({ type: "setConnectedState", payload: false });
    }
  }, [address, initialContract]);

  const connectMetaMask = async (event) => {
    event.preventDefault();
    dispatch({
      type: "setConnectError",
      payload: "",
    });
    try {
      dispatch({
        type: "setIsLoading",
        payload: true,
      });
      const provider = await detectEthereumProvider();

      if (!provider) {
        onboarding.current.startOnboarding();
        return;
      }
      // if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
      //   console.log("start");
      //   onboarding.current.startOnboarding();
      //   return;
      // }
      const newAccounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const netID = Number(window.ethereum.chainId);

      if (netID !== 1 && ENV === "prod") {
        dispatch({
          type: "setConnectError",
          payload: "Please connect to Ethereum Mainnet",
        });
      } else if (netID !== 4 && ENV !== "prod") {
        dispatch({
          type: "setConnectError",
          payload: "Please connect to Rinkeby Mainnet",
        });
      } else {
        dispatch({ type: "setAccount", payload: newAccounts[0] });
      }
    } catch (error) {
      if (error.message.includes("Already processing eth_requestAccounts")) {
        dispatch({
          type: "setConnectError",
          payload: "Please unlock your metamask",
        });
      } else {
        dispatch({ type: "setConnectError", payload: error.message });
      }
    } finally {
      dispatch({
        type: "setIsLoading",
        payload: false,
      });
    }
  };

  const reveal = async (event) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      dispatch({
        type: "setIsLoading",
        payload: true,
      });
      // await sign function
      const signature = await sign(address);
      const result = await revealToken({
        signature,
        tokenId: selectedToken.tokenId,
      });

      dispatch({
        type: "setSelectedToken",
        payload: result,
      });

      setRevealing(true);

      setTimeout(async () => {
        setRevealing(false);

        party.confetti(mainRef.current, {
          count: party.variation.range(80, 90),
        });
        initialContract();
        dispatch({
          type: "setSuccessMessage",
          payload: t("revealSuccess"),
        });
      }, "18000");
    } catch (error) {
      dispatch({
        type: "setErrorMessage",
        payload: t("revealError"),
      });
    } finally {
      dispatch({
        type: "setIsLoading",
        payload: false,
      });
    }
  };

  return (
    <div className="reveal-page pt-18 text-white">
      <div className="bg-image" />
      <div ref={mainRef} className="main flex flex-col items-center">
        {connectError ? (
          <Alert
            {...{
              color: "red",
              message: connectError,
              close: () => {
                dispatch({ type: "setConnectError", payload: "" });
              },
            }}
          />
        ) : (
          <p className="my-2 font-semibold address text-2xl">
            {t("walletAddress")}: {address}
          </p>
        )}
        <div className="flex flex-col justify-center items-center ">
          {isConnected && accountTokens && accountTokens.length > 0 ? (
            <div>
              <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 grid-cols-1">
                {accountTokens.map((tokenData, index) => (
                  <Token
                    key={index}
                    tokenData={tokenData}
                    dispatch={dispatch}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div>
              <LoadingButton onClick={connectMetaMask} isLoading={isLoading}>
                {t("connect")}
              </LoadingButton>
            </div>
          )}
        </div>
      </div>
      {selectedToken && (
        <div
          className={`relative z-10 ${showModel ? "" : "hidden"}`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />

          <div
            className="model fixed z-10 inset-0 overflow-y-auto flex flex-col justify-center items-center"
            onClick={() => {
              if (!revealing)
                dispatch({
                  type: "setShowModel",
                  payload: false,
                });
            }}
          >
            <div className="modal-container flex flex-col justify-center items-center bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all">
              <div className="px-4 py-5 pb-4 flex flex-col justify-center items-center">
                <p className="mt-4 font-semibold text-2xl">
                  #{selectedToken.tokenId}
                </p>
                {revealing ? (
                  <div>
                    <video
                      className="moai-video reveal-video"
                      autoPlay="autoPlay"
                    >
                      <source
                        src="https://storage.googleapis.com/moai-storage/blind-box3.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                ) : selectedToken.image === IPFS_BLIND_BOX ? (
                  <img
                    className="pic reveal-pic"
                    src={GOOGLE_BLIND_BOX}
                    alt="Moai Family Gif"
                  />
                ) : (
                  <img
                    className="pic reveal-pic"
                    src={`${GOOGLE_NFT_PATH}${
                      selectedToken.image.includes("ipfs")
                        ? selectedToken.image.split("/")[3]
                        : selectedToken.image.split("/")[5]
                    }`}
                    alt="Moai Family Preview"
                  />
                )}
              </div>
              {!revealing && (
                <div className="pb-4">
                  {successMessage && (
                    <Alert
                      {...{
                        color: "green",
                        message: successMessage,
                        close: (event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          dispatch({ type: "setSuccessMessage", payload: "" });
                        },
                      }}
                    />
                  )}
                  {errorMessage && (
                    <Alert
                      {...{
                        color: "red",
                        message: errorMessage,
                        close: (event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          dispatch({ type: "setErrorMessage", payload: "" });
                        },
                      }}
                    />
                  )}
                  {!successMessage && !errorMessage && (
                    <>
                      {selectedToken.image === IPFS_BLIND_BOX && (
                        <LoadingButton
                          className="mx-2 text-2xl mt-1 mb-1"
                          isLoading={isLoading}
                          onClick={reveal}
                        >
                          {t("reveal")}
                        </LoadingButton>
                      )}
                      <LoadingButton
                        className="mx-2 text-2xl mt-1 mb-1"
                        isLoading={isLoading}
                        onClick={() => {
                          dispatch({
                            type: "setShowModel",
                            payload: false,
                          });
                        }}
                      >
                        {t("cancel")}
                      </LoadingButton>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Claim;
