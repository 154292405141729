import Fade from "react-reveal/Fade";

const Image = ({ image }) => (
  <div className="w-full p-5 flex flex-col justify-center items-center">
    <Fade>
      <img className="rounded-lg" src={image} alt="Moai Family Preview" />
    </Fade>
  </div>
);

const imageArray = [
  { image: require("image/art/1.png") },
  { image: require("image/art/2.png") },
  { image: require("image/art/3.png") },
  { image: require("image/art/4.png") },
];

const SecondSection = () => (
  <section
    id="art"
    className="second-section flex flex-col items-center bg-black text-white"
  >
    <div className="container mx-auto">
      <div id="gallery">
        <Fade down>
          <div className="text-white py-20 flex flex-col justify-center items-center section-title">
            <p className="section-title">[ GALLERY ]</p>
          </div>
        </Fade>
      </div>
      <div className="grid md:grid-cols-4 grid-cols-2">
        {imageArray.map((item) => (
          <Image {...item} />
        ))}
      </div>
    </div>
  </section>
);
export default SecondSection;
