import React, { useState, useEffect, useRef, useReducer } from "react";
import party from "party-js";
import LoadingButton from "components/LoadingButton";
import MetaMaskOnboarding from "@metamask/onboarding";
import classNames from "classnames";
import bigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import {
  addTokenToMetaMask,
  getOwnedTokenIds,
  getTotalSupply,
} from "../../services/wallet";
import {
  getClaimableForTokens,
  getClaimableForUser,
  claimToken,
} from "../../services/claim/";
import {
  ENV,
  GOOGLE_BLIND_BOX,
  GOOGLE_NFT_PATH,
  IPFS_BLIND_BOX,
} from "../../constant/config";
import { getTokensData } from "../../api";
import "./index.scss";

const ETHER_GWEI = 1000000000000000000;

const initialState = {
  isConnected: false,
  address: null,
  successMessage: "",
  errorMessage: "",
  isLoading: false,
  accountReward: 0,
  accountTokensId: [],
  isAddedToken: false,
  neverRemindMe: false,
  isAllSelected: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "setConnectedState":
      return { ...state, isConnected: action.payload };
    case "setAccount":
      return { ...state, address: action.payload };
    case "setErrorMessage":
      return { ...state, errorMessage: action.payload };
    case "setSuccessMessage":
      return { ...state, successMessage: action.payload };
    case "setIsLoading":
      return { ...state, isLoading: action.payload };
    case "setAccountReward":
      return { ...state, accountReward: action.payload };
    case "setAccountTokensId":
      return { ...state, accountTokensId: action.payload };
    case "setTotalSupply":
      return { ...state, totalSupply: action.payload };
    case "setIsAddedToken":
      return { ...state, isAddedToken: action.payload };
    case "setNeverRemindMe":
      return { ...state, neverRemindMe: action.payload };
    case "setIsAllSelected":
      return { ...state, isAllSelected: action.payload };
    default:
      throw new Error();
  }
}

const Token = React.memo(
  ({
    tokenData,
    handleRewardUseCallback,
    selectToken,
    removeToken,
    selectedToken,
  }) => {
    const [mfPoint, setMfPoint] = useState(0);
    const [selected, setSelected] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
      const result = selectedToken.some(
        (element) => +element === +tokenData.tokenId
      );

      setSelected(result);
    }, [selectedToken, selectedToken.length, tokenData]);

    useEffect(() => {
      async function init() {
        const result = await getClaimableForTokens([tokenData.tokenId]);
        const claimableMF = new bigNumber(result);
        const tokenReward = claimableMF.dividedBy(ETHER_GWEI).toFixed(2);

        setMfPoint(+tokenReward);
      }
      init();
    }, [tokenData]);

    const isBlindBox = tokenData.image === IPFS_BLIND_BOX;

    const imageName = isBlindBox
      ? GOOGLE_BLIND_BOX
      : `${GOOGLE_NFT_PATH}${
          tokenData.image.includes("ipfs")
            ? tokenData.image.split("/")[3]
            : tokenData.image.split("/")[5]
        }`;

    return (
      <div
        className="flex flex-col items-center p-5"
        onClick={() => {
          if (selected) {
            removeToken(tokenData.tokenId);
            handleRewardUseCallback(-mfPoint);
          } else {
            selectToken(tokenData.tokenId);
            handleRewardUseCallback(mfPoint);
          }
        }}
      >
        <p className="mt-4 font-semibold text-2xl">
          #{tokenData.tokenId} MFT: {mfPoint}
        </p>
        {isBlindBox ? (
          <img
            className={classNames("pic", {
              selected: selected,
            })}
            src={imageName}
            alt="Moai Family Gif"
          />
        ) : (
          <img
            className={classNames("pic", {
              selected: selected,
            })}
            src={imageName}
            alt="Moai Family Preview"
          />
        )}

        <LoadingButton>{selected ? t("remove") : t("select")}</LoadingButton>
      </div>
    );
  }
);

const Alert = ({ color, message, close }) => {
  return (
    <div
      className="text-center py-4 lg:px-4 absolute alert hover:text-black"
      onClick={close}
    >
      <button
        className={`p-2 ${
          color === "green" ? "bg-green-700" : "bg-red-700"
        }  flex flex-row justify-center items-center leading-none rounded-xlrelative`}
        role="alert"
      >
        <div className="font-semibold text-4xl mr-2 ml-2 text-left flex-auto">
          {message}
        </div>
        <div
          className="flex flex-col justify-center items-center font-semibold text-2xl"
          onClick={close}
        >
          x
        </div>
      </button>
    </div>
  );
};

function Claim() {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [rewardToClaim, setRewardToClaim] = useState(0);
  const [accountTokens, setAccountTokens] = useState([]);
  const [selectedToken, setSelectedToken] = useState([]);
  const [searchedToken, setSearchedToken] = useState("");
  const [searchedTokenReward, setSearchedTokenReward] = useState();

  const selectToken = React.useCallback(
    (_tokenId) => {
      setSelectedToken([...selectedToken, +_tokenId]);
    },
    [setSelectedToken, selectedToken]
  );

  const removeToken = React.useCallback(
    (_tokenId) => {
      for (var i = 0; i < selectedToken.length; i++) {
        if (+selectedToken[i] === +_tokenId) {
          selectedToken.splice(i, 1);
          break;
        }
      }

      setSelectedToken([...selectedToken]);
    },
    [setSelectedToken, selectedToken]
  );

  const handleRewardUseCallback = React.useCallback(
    (arg) => {
      const data = arg + rewardToClaim;

      setRewardToClaim(data);
    },
    [setRewardToClaim, rewardToClaim]
  );

  const {
    isConnected,
    address,
    accountReward,
    errorMessage,
    isLoading,
    successMessage,
    accountTokensId,
    totalSupply,
    isAddedToken,
    neverRemindMe,
    isAllSelected,
  } = state;

  const onboarding = useRef();
  const mainRef = useRef(null);

  useEffect(() => {
    const _neverRemindMe = localStorage.getItem("neverRemindMe");

    if (_neverRemindMe) {
      dispatch({ type: "setIsAddedToken", payload: _neverRemindMe });
    }
    getTotalSupply().then((result) => {
      dispatch({ type: "setTotalSupply", payload: +result });
    });
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
    const handleNewAccounts = (newAccounts) => {
      dispatch({ type: "setAccount", payload: newAccounts[0] });
    };
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.on("accountsChanged", handleNewAccounts);
      return () => {
        if (window.ethereum.off && window.ethereum) {
          window.ethereum.off("accountsChanged", handleNewAccounts);
        }
      };
    }
  }, []);

  const initialContract = React.useCallback(async () => {
    try {
      dispatch({
        type: "setIsLoading",
        payload: true,
      });
      setRewardToClaim(0);
      setSelectedToken([]);

      // init must have address
      if (!address) return;

      const accountTokensId = await getOwnedTokenIds(address);

      if (!accountTokensId.length > 0)
        return dispatch({
          type: "setErrorMessage",
          payload: "No token",
        });

      const reward = await getClaimableForUser(address);
      const claimableMF = new bigNumber(reward);
      const accountReward = claimableMF.dividedBy(ETHER_GWEI).toFixed(2);

      const tokensData = await getTokensData({
        tokensId: accountTokensId.join(),
      });

      setAccountTokens(tokensData);

      dispatch({
        type: "setAccountTokensId",
        payload: accountTokensId,
      });
      dispatch({
        type: "setAccountReward",
        payload: +accountReward,
      });
    } catch (error) {
      dispatch({
        type: "setErrorMessage",
        payload: "initial contract error",
      });
    } finally {
      dispatch({
        type: "setIsLoading",
        payload: false,
      });
    }
  }, [address]);

  useEffect(() => {
    if (!MetaMaskOnboarding.isMetaMaskInstalled()) return;

    if (address) {
      dispatch({ type: "setConnectedState", payload: true });
      onboarding.current.stopOnboarding();
      setAccountTokens([]);
      initialContract();
    } else {
      dispatch({ type: "setConnectedState", payload: false });
    }
  }, [address, initialContract]);

  const connectMetaMask = async (event) => {
    event.preventDefault();
    dispatch({
      type: "setErrorMessage",
      payload: "",
    });
    try {
      dispatch({
        type: "setIsLoading",
        payload: true,
      });
      if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
        onboarding.current.startOnboarding();
        return;
      }
      const newAccounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const netID = Number(window.ethereum.chainId);

      if (netID !== 1 && ENV === "prod") {
        dispatch({
          type: "setErrorMessage",
          payload: "Please connect to Ethereum Mainnet",
        });
      } else if (netID !== 4 && ENV !== "prod") {
        dispatch({
          type: "setErrorMessage",
          payload: "Please connect to Rinkeby Mainnet",
        });
      } else {
        dispatch({ type: "setAccount", payload: newAccounts[0] });
      }
    } catch (error) {
      if (error.message.includes("Already processing eth_requestAccounts")) {
        dispatch({
          type: "setErrorMessage",
          payload: "Please unlock your metamask",
        });
      } else {
        dispatch({ type: "setErrorMessage", payload: error.message });
      }
    } finally {
      dispatch({
        type: "setIsLoading",
        payload: false,
      });
    }
  };

  const claim = async (event) => {
    try {
      event.preventDefault();
      dispatch({
        type: "setIsLoading",
        payload: true,
      });

      if (selectedToken.length === 0) {
        return dispatch({
          type: "setErrorMessage",
          payload: "Please select token",
        });
      }

      await claimToken(selectedToken, address);

      setRewardToClaim(0);
      setSelectedToken([]);
      party.confetti(mainRef.current, {
        count: party.variation.range(80, 90),
      });

      dispatch({
        type: "setSuccessMessage",
        payload: t("claim.success"),
      });
      initialContract();
    } catch (error) {
      dispatch({
        type: "setErrorMessage",
        payload: t("claim.error"),
      });
    } finally {
      dispatch({
        type: "setIsLoading",
        payload: false,
      });
    }
  };

  const ClaimButton = React.memo(({ children, isLoading }) => {
    return (
      <button
        className="whitespace-nowrap claim-button mt-4 mb-2 button shadow-md max-w-min font-semibold text-4xl"
        onClick={claim}
      >
        <div className="relative">
          <div className={isLoading ? "opacity-0" : "opacity-100"}>
            {children}
          </div>
          <div
            className={`absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
              isLoading ? "" : "hidden"
            }`}
          >
            <div className="rounded-full border-2 animate-spin border-black-400 w-6 h-6 border-t-transparent " />
          </div>
        </div>
      </button>
    );
  });

  return (
    <div className="claim-page pt-18 text-white">
      <div className="bg-image"></div>
      <div className="main flex flex-col items-center" ref={mainRef}>
        <div className="flex justify-center items-center shadow rounded-3xl card row-span-2 col-span-2 grid-cols-2 m-6 bg-black">
          <div className="font-semibold text-2xl">
            Moai Family NFT {t("claim.claimCheck")}
          </div>

          <div className="ml-4 font-semibold text-2xl">#</div>
          <input
            type="text"
            className="input mr-4"
            onChange={(event) => {
              event.preventDefault();
              setSearchedToken(event.target.value);
            }}
            value={searchedToken}
          />
          <LoadingButton
            className="mx-2 my-0 py-0 revert search"
            onClick={async () => {
              try {
                dispatch({
                  type: "setIsLoading",
                  payload: true,
                });
                setSearchedTokenReward();

                if (+searchedToken > totalSupply - 1) {
                  throw new Error();
                }

                const result = await getClaimableForTokens([searchedToken]);

                const claimableMF = new bigNumber(result);
                const tokenReward = claimableMF
                  .dividedBy(ETHER_GWEI)
                  .toFixed(2);

                setSearchedTokenReward(tokenReward);
              } catch (error) {
                dispatch({
                  type: "setErrorMessage",
                  payload: "Search error",
                });
              } finally {
                dispatch({
                  type: "setIsLoading",
                  payload: false,
                });
              }
            }}
            isLoading={isLoading}
          >
            {t("claim.search")}
          </LoadingButton>
          {searchedTokenReward && (
            <>
              <p className="ml-4 font-semibold text-2xl">
                {searchedTokenReward}{" "}
              </p>
              <p className="ml-2 font-semibold text-xl">MFT</p>
            </>
          )}
        </div>

        <div className="flex flex-col justify-center items-center ">
          {!(isConnected && accountTokens && accountTokens.length) ? (
            <LoadingButton onClick={connectMetaMask} isLoading={isLoading}>
              {t("connect")}
            </LoadingButton>
          ) : !isAddedToken ? (
            <div className="flex flex-col justify-center items-center">
              <LoadingButton
                className="whitespace-nowrap"
                onClick={async () => {
                  try {
                    await addTokenToMetaMask();
                    dispatch({
                      type: "setSuccessMessage",
                      payload: "Add token successfully",
                    });
                    dispatch({ type: "setIsAddedToken", payload: true });
                  } catch (error) {
                    dispatch({
                      type: "setErrorMessage",
                      payload: error.message,
                    });
                  }
                }}
                isLoading={isLoading}
              >
                {t("claim.addToken")}
              </LoadingButton>

              <p className="ml-1 my-0 font-semibold address text-xl">
                {t("claim.alreadyAdded")}
              </p>

              <div className="flex justify-center items-center">
                <input
                  type="checkbox"
                  checked={neverRemindMe}
                  onChange={() => {
                    dispatch({
                      type: "setNeverRemindMe",
                      payload: !neverRemindMe,
                    });
                  }}
                />
                <p className="ml-1 my-0  address text-xl">
                  {t("claim.neverRemind")}
                </p>
              </div>
              <LoadingButton
                className="whitespace-nowrap mb-0 mt-6"
                onClick={async () => {
                  if (neverRemindMe) {
                    localStorage.setItem("neverRemindMe", true);
                  }

                  dispatch({ type: "setIsAddedToken", payload: true });
                }}
                isLoading={isLoading}
              >
                Next&rarr;
              </LoadingButton>
            </div>
          ) : (
            <div>
              <div className="flex flex-col justify-center items-center">
                <p className="my-2 font-semibold address text-2xl">
                  {t("walletAddress")}: {address}
                </p>
                <p className="font-semibold text-2xl">
                  {t("claim.total")} Moai Family Token: {accountReward}
                </p>
                <LoadingButton
                  className="whitespace-nowrap"
                  onClick={() => {
                    // dispatch({ type: "setAccount", payload: newAccounts[0] });
                    if (isAllSelected) {
                      setSelectedToken([]);
                      setRewardToClaim(0);
                      dispatch({ type: "setIsAllSelected", payload: false });
                    } else {
                      const data = JSON.parse(JSON.stringify(accountTokensId));

                      setSelectedToken(data);
                      setRewardToClaim(+accountReward);
                      dispatch({ type: "setIsAllSelected", payload: true });
                    }
                  }}
                  isLoading={isLoading}
                >
                  {isAllSelected ? t("claim.removeAll") : t("claim.selectAll")}
                </LoadingButton>
              </div>
              <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-3 grid-cols-2">
                {accountTokens.map((tokenData, index) => (
                  <Token
                    key={index}
                    tokenData={tokenData}
                    handleRewardUseCallback={handleRewardUseCallback}
                    selectToken={selectToken}
                    removeToken={removeToken}
                    selectedToken={selectedToken}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="fixed flex justify-end items-center w-full claim-bar bg-black">
        <div className="pr-6 max-w-min font-semibold text-4xl">
          {new bigNumber(rewardToClaim).toFixed(2)}
        </div>
        {successMessage && (
          <Alert
            {...{
              color: "green",
              message: successMessage,
              close: () => {
                dispatch({ type: "setSuccessMessage", payload: "" });
              },
            }}
          />
        )}
        {errorMessage && (
          <Alert
            {...{
              color: "red",
              message: errorMessage,
              close: () => {
                dispatch({ type: "setErrorMessage", payload: "" });
              },
            }}
          />
        )}
        {!successMessage && !errorMessage && (
          <ClaimButton isLoading={isLoading}>{t("claim.claim")}</ClaimButton>
        )}
      </div>
    </div>
  );
}

export default Claim;
