import { ethers } from "ethers";
import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";

function hash(address, number) {
  // let check_summed_address = ethers.utils.getAddress(address);

  // if (check_summed_address !== address) {
  //   console.log(`checksum ${address} to ${check_summed_address}`);
  // }
  return Buffer.from(
    ethers.utils
      .solidityKeccak256(["address", "uint256"], [address, number])
      .slice(2),
    "hex"
  );
}

function GetRoot(whitelist) {
  const merkleTree = new MerkleTree(
    Object.entries(whitelist).map((item) => hash(...item)),
    keccak256,
    { sortPairs: true }
  );
  console.log("root", merkleTree.getHexRoot());
}

function Proof(address, number, whitelist) {
  const merkleTree = new MerkleTree(
    Object.entries(whitelist).map((item) => hash(...item)),
    keccak256,
    { sortPairs: true }
  );

  return merkleTree.getHexProof(hash(address, number));
}

export { Proof, GetRoot };
