const env = process.env;

const ENV = env.REACT_APP_ENV;
const CONTRACT_ADDRESS = env.REACT_APP_CONTRACT_ADDRESS;
const CONTRACT_ADDRESS_REWARD = env.REACT_APP_CONTRACT_ADDRESS_REWARD;
const CONTRACT_ADDRESS_TOKEN = env.REACT_APP_CONTRACT_ADDRESS_TOKEN;

const IPFS_BLIND_BOX = env.REACT_APP_IPFS_BLIND_BOX;
const GOOGLE_BLIND_BOX = env.REACT_APP_GOOGLE_BLIND_BOX;
const GOOGLE_NFT_PATH = env.REACT_APP_GOOGLE_NFT_PATH;
const OPENSEA = env.REACT_APP_OPENSEA;
const API = env.REACT_APP_API;


export {
  API,
  ENV,
  OPENSEA,
  IPFS_BLIND_BOX,
  GOOGLE_NFT_PATH,
  GOOGLE_BLIND_BOX,
  CONTRACT_ADDRESS,
  CONTRACT_ADDRESS_REWARD,
  CONTRACT_ADDRESS_TOKEN,
};
