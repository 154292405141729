import { Fade, Bounce } from "react-reveal";

const FourthSection = () => (
  <section
    id="about"
    className="about bg-black text-white flex flex-col items-center"
  >
    <div className="container flex flex-col items-center">
      <div className="py-20 flex flex-col justify-center items-center section-title">
        <Fade down>
          <p className="section-title title mt-4">[ ABOUT MOAI FAMILY ]</p>
        </Fade>
      </div>
      <div className="flex flex-col items-center px-4">
        <Bounce right>
          <div className=" w-full flex items-center flex-col">
            <p className="font-bold text-xl">
              Moai Family is a collection of 1,800 NFTs-unique Moai Kaijin
              generative portraits on the Ethereum blockchain.
              <br />
              <br />
              Every Moai Family NFT represents a membership card for various
              benefits, including exclusive channels, consumption discounts for
              different categories, and all kinds of useful knowledge about the
              investment and the crypto world.
              <br />
              <br />
              Your Moai Family NFT will also be your VIP pass to enter our
              "Moaiverse" wonderland. So you can enjoy not only free concerts
              but also our play-to-earn games.
              <br />
              <br />
              Holders are able to interact with celebrities and KOLs online and
              even meet them in person, to build a closer connection with all
              family members.
              <br />
              <br />
              We are committed to building a family-like relationship with every
              member. Loving and caring for each other in the real world, crypto
              world, and the future world is our core beliefs.
              <br />
              <br />
              With your help, we believe that we can definitely build a lovely,
              healthy, organic, and strong family together.
            </p>
            <p className="moai-font sm:text-5xl text-3xl pt-20">
              We build together, we own together
            </p>
          </div>
        </Bounce>
        <div />
      </div>
    </div>
  </section>
);
export default FourthSection;
