const LoadingButton = ({ className, children, onClick, isLoading }) => {
  return (
    <button
      className={`whitespace-nowrap mt-4 mb-4 button rounded-3xl shadow-md max-w-min font-semibold ${className}`}
      onClick={onClick}
    >
      <div className="relative">
        <div className={isLoading ? "opacity-0" : "opacity-100"}>
          {children}
        </div>
        <div
          className={`absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
            isLoading ? "" : "hidden"
          }`}
        >
          <div className="rounded-full border-2 animate-spin border-black-400 w-6 h-6 border-t-transparent " />
        </div>
      </div>
    </button>
  );
};
export default LoadingButton;
