// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./i18n/en.json";
import tw from "./i18n/zh-TW.json";

const resources = {
  en: {
    translation: en,
  },
  cn: {
    translation: tw,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", //default language
  fallbackLng: "en", //if switch language not exist use en as default
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
