import { API } from "constant/config";

const baseUrl = API;

const getTokensData = async ({ tokensId }) => {
  const response = await fetch(`${baseUrl}?tokensId=${tokensId}`);

  if (response.ok) {
    // response.status >= 200 && response.status < 300
    return await response.json();
  } else {
    throw new Error("getTokensData");
  }
};

const revealToken = async ({ tokenId, signature }) => {
  const response = await fetch(`${baseUrl}/reveal/${tokenId}`, {
    method: "post",
    body: JSON.stringify({ signature }),
    headers: { "Content-Type": "application/json" },
  });

  if (response.ok) {
    // response.status >= 200 && response.status < 300
    return await response.json();
  } else {
    throw new Error("reveal");
  }
};

export { getTokensData, revealToken };
